.about {
  margin-bottom: 80px;
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.about p {
  font-size: 16px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 1.8;
  color: #444;
}

.about .container {
  display: flex;
  gap: 30px;
}

.about .left {
  flex: 2;
}

.about .right {
  flex: 1;
}

.about .right img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Info Section: Overview, Mission, Objectives */
.info-section {
  display: flex;
  gap: 30px;
  margin-top: 50px;
}

.info-box {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.info-box h3 {
  font-size: 22px;
  color: #2c3e50;
  margin-bottom: 15px;
  border-bottom: 2px solid #2ecc71;
  padding-bottom: 5px;
}

.info-box p,
.info-box ul {
  margin: 10px 0;
  font-size: 15px;
  color: #555;
}

.info-box ul {
  margin-left: 20px;
}

.info-box ul li {
  list-style: disc;
  margin-bottom: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }

  .about .right img {
    margin-top: 30px;
  }

  .info-section {
    flex-direction: column;
    gap: 30px;
  }
}
