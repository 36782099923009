/* Styles for Featured Section */
.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  width: 400px; /* Set a constant box width */
  height: 400px; /* Set a constant box height */
}

.featured .box:hover {
  transform: scale(1.05); /* Slightly enlarge the box on hover */
  box-shadow: 0 4px 15px rgb(112 121 138 / 25%);
}

.featured img {
  width: 80%; /* Increase image size within the box */
  height: 280px; /* Larger height for images */
  margin: 0 auto; /* Center the image horizontally */
  display: block; /* Ensure the image is treated as a block-level element */
  object-fit: cover; /* Ensure the image fits within the rectangle */
  border-radius: 8px; /* Optional: Rounded corners for images */
}

.featured h4 {
  font-size: 18px; /* Font size for titles */
  margin-top: 15px; /* Add spacing between the image and title */
  font-weight: bold;
  color: #333;
}

.featured label {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
  display: block;
}

/* Center the boxes within the grid */
.content.grid5 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Spacing between boxes */
  justify-content: center; /* Center the boxes horizontally */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .featured .box {
    width: 200px; /* Smaller box width for tablets */
    height: 260px; /* Adjust height for smaller screens */
  }

  .featured img {
    height: 150px; /* Adjust image height for tablets */
  }

  .featured h4 {
    font-size: 16px; /* Adjust font size */
  }

  .featured label {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .featured .box {
    width: 180px; /* Smaller box width for mobile */
    height: 240px; /* Further adjust height for smaller screens */
  }

  .featured img {
    height: 130px; /* Further reduce image height for smaller screens */
  }

  .featured h4 {
    font-size: 14px;
  }

  .featured label {
    font-size: 12px;
  }
}

/* Additional Styles for Services Section */
.additional-content {
  margin-top: 50px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background: #ffffff;
  border: 1px solid rgba(62, 28, 131, 0.1);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 45%;
}

.card h3 {
  color: #007bff;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.card p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.card ul {
  margin-left: 20px;
  list-style-type: disc;
}

.card ul li {
  margin-bottom: 10px;
}

/* Responsive Design for Services Section */
@media screen and (max-width: 768px) {
  .additional-content {
    flex-direction: column;
  }

  .card {
    min-width: 100%;
  }
}
