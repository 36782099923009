.hero {
  height: 90vh;
  width: 100%;
  background-image: url("../../../../public/images/list/NIS2024.jpg"), url("../../../../public/images/top.jpg"), url("../../../../public/images/top2.jpeg"), url("../../../../public/images/top3.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: slide 15s infinite;
}

@keyframes slide {
  0%{
    background-image: url("../../../../public/images/top.jpg");
  }
  25% {
    background-image: url("../../../../public/images/list/NIS2024.jpg") ;
  }
  50% {
    background-image: url("../../../../public/images/top2.jpeg");
  }
  75% {
    background-image: url("../../../../public/images/top3.jpeg");
  }
  100% {
    background-image: url("../../../../public/images/top.jpg");
  }
}

.hero .container {
  padding-top: 10%;
}

.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}
form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}
form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}
form span {
  font-size: 14px;
  color: grey;
}
input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}
