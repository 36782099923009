header {
  background-color: #fff;
  height: 15vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}

header img {
  width: 90px;
  height: 90px;
}

header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
}

header a:hover {
  color: #27ae60;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #27ae60;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

header .small {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 15vh;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 20px 0;
  z-index: 9999;
}

header .small li {
  margin: 10px 0;
}

header .small a {
  color: #000;
  font-weight: 500;
}

header .small a:hover {
  color: #27ae60;
}

/* Center the toggle button and display */
header .toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 20px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
}
