/* Default Back Component Styling (Desktop View) */
.contact .back {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  /* background-image: url('../images/right.jpg'); Use the image path directly */
  background-size: cover; /* Ensures the image covers the area */
  background-repeat: no-repeat; /* Avoid repeated images */
  background-position: center; /* Centers the image */
  height: 300px; /* Fixed height for uniform display */
}

.contact .back h2 {
  font-size: 32px;
  margin-bottom: 10px;
  word-wrap: break-word;
  max-width: 90%;
}

.contact .back p {
  font-size: 18px;
  margin: 0;
  max-width: 90%;
}

.contact .back::before {
  content: "";
  display: none;
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
}

/* .contact .back * {
  position: relative;
  z-index: 2;
} */

/* Contact Form and Map Layout Styling */
.contact .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}

.contact-layout {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: flex-start;
}

.contact-layout .map-container {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-layout .map-container iframe {
  width: 100%;
  height: 400px;
  border: 0;
}

.contact-layout form {
  flex: 1;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-layout form h4 {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.map-container h4 {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.contact-layout form div {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.contact-layout input,
.contact-layout textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
}

.contact-layout textarea {
  resize: none;
  height: 120px;
}

.contact-layout input:focus,
.contact-layout textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.contact-layout button {
  display: block;
  width: 100%;
  background: #007bff;
  color: #ffffff;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-layout button:hover {
  background: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .contact-layout {
    flex-direction: column;
  }

  .contact-layout .map-container {
    margin-bottom: 20px;
  }

  .contact .back {
    height: 200px;
    /* background: url('../images/right.jpg') center/cover no-repeat, #007bff; */
  }
}
