/* Team Section Styles */
.team .box {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(62, 28, 131, 0.1);
  padding: 1rem;
  transition: 0.5s;
}

.team .box:hover {
  box-shadow: 0 0 20px 0 rgba(112, 121, 138, 0.18);
  cursor: pointer;
}

.team .details {
  text-align: center;
  margin-top: 30px;
}

.team .img {
  width: 120px;
  height: 120px;
  margin: auto;
  position: relative;
}

.team img {
  width: 110%;
  height: 110%;
  border-radius: 50%;
}

.team .img i {
  color: #108be7;
  font-size: 17px;
  position: absolute;
  top: 35px;
  right: -20px;
}

/* .team .img::after {
  content: "";
  position: absolute;
  top: -6.5px;
  left: -2px;
  width: 115px;
  height: 115px;
  border: 4.5px solid #edf0f5;
  border-radius: 50%;
} */

.team label {
  display: inline-block;
  margin-top: 20px;
}

.team i {
  color: grey;
  margin-right: 5px;
}

.team ul {
  display: inline-block;
  margin-top: 10px;
}

.team ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  background-color: #f4f5f7;
  border-radius: 50%;
  margin: 5px;
}

.team .button {
  margin-top: 20px;
}

.team .button i {
  color: white;
  font-size: 20px;
}

/* Show More Button Styles */
.show-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-show-more {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-show-more:hover {
  background-color: #0056b3;
}

/* Associate Members Section */
.associate-members {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.associate-box {
  background: #ffffff;
  border: 1px solid rgb(62 28 131 / 10%);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  transition: 0.5s;
}

.associate-box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
}

.associate-box h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.associate-box label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-size: 14px;
}

.associate-box p {
  margin: 5px 0;
  font-size: 14px;
  color: #777;
}

/* General Members Section */
.general-members {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.general-box {
  background: #ffffff;
  border: 1px solid rgb(62 28 131 / 10%);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  transition: 0.5s;
}

.general-box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
}

.general-box .img {
  width: 120px;
  height: 120px;
  margin: auto;
  position: relative;
}

.general-box img {
  width: 100%;
  height: 110px;
  border-radius: 50%;
}

.general-box h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.general-box label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-size: 14px;
}

.general-box p {
  margin: 5px 0;
  font-size: 14px;
  color: #777;
}

/* Ensure proper grid layout */
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* Responsive Design */
@media screen and (max-width: 800px) {
  .grid3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .team .details {
    margin-top: 20px;
  }

  .team .img {
    width: 100px;
    height: 100px;
  }

  .team img {
    height: 100px;
  }

  .team ul li {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .btn-show-more {
    padding: 8px 16px;
    font-size: 14px;
  }

  .associate-box, .general-box {
    margin-bottom: 20px;
  }

  .team .img::after {
     /* width: 90px; /* Smaller width for mobile devices 
    height: 90px; /* Smaller height for mobile devices 
    top: -5px; /* Adjust positioning for smaller size 
    left: -1.5px; /* Adjust positioning for smaller size 
    border: 3.5px solid #edf0f5; Adjust border thickness */
    display: none;
  } 
}