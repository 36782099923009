/* Existing styles for Recent Section */
.recent .text {
  padding: 30px;
}
.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
}
.recent .category i {
  font-size: 20px;
  color: #bec7d8;
}
.recent p {
  color: #72809d;
}
.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 10px 30px;
}

/* New styles for Timeline Section */
.timeline {
  margin-top: 50px;
  padding: 20px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.timeline-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.timeline-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.timeline-card {
  background: #ffffff;
  box-shadow: 0 0 20px rgb(112 121 138 / 18%);
  border-radius: 8px;
  padding: 20px;
  width: 280px;
  text-align: left;
}

.timeline-card h3 {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

.timeline-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.timeline-card ul li {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

/* Responsive design for Timeline Section */
@media screen and (max-width: 768px) {
  .timeline-card {
    width: 100%;
  }
}
