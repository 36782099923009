/* Footer Contact Section */
.footerContact {
  background-color: #27ae60;
  padding: 40px 0;
  color: #fff;
}

.footerContact h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.footerContact p {
  font-size: 18px;
  margin-bottom: 20px;
}

.footerContact .btn5 {
  background-color: #fff;
  color: #27ae60;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footerContact .btn5:hover {
  background-color: #1d9e50;
  color: #fff;
}

/* Footer Styles */
footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}

footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive column layout */
  gap: 40px; /* Equal spacing between columns */
  align-items: center;
  text-align: center; /* Center-align content */
}

/* Footer Boxes */
footer .box {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align each box */
}

footer .box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

footer .box ul {
  list-style: none;
  padding: 0;
}

footer .box ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: grey;
}

footer .box ul li a {
  color: grey;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer .box ul li a:hover {
  color: #fff;
}

/* Logo and Text Section */
footer .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

footer .logo img {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

footer .logo .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .logo .content h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 10px;
  color: #fff;
}

footer .logo .content p {
  margin: 10px 0 20px;
  color: grey;
  font-size: 16px;
}

footer .logo .content .input button {
  background-color: #27ae60;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

footer .logo .content .input button:hover {
  background-color: #1d9e50;
}

/* Social Media Section */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0;
  list-style: none;
}

.social-icons li a {
  color: grey;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons li a:hover {
  color: #fff;
}


/* Legal Section */
.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
}

/* Responsive Design */
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: 1fr; /* Stack items vertically */
    gap: 30px;
  }

  footer .logo img {
    margin-bottom: 20px;
  }

  footer .logo .content h2 {
    font-size: 18px;
  }

  footer .logo .content p {
    font-size: 14px;
  }

  footer .logo .content .input button {
    padding: 10px 20px;
    font-size: 14px;
  }

  footer .box ul li {
    font-size: 14px;
  }

  footer .social-media a {
    font-size: 20px;
  }

  .legal {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  footer .logo img {
    width: 120px;
  }

  footer .logo .content h2 {
    font-size: 16px;
  }

  footer .logo .content p {
    font-size: 12px;
  }

  footer .logo .content .input button {
    font-size: 12px;
    padding: 8px 16px;
  }

  footer .box h3 {
    font-size: 16px;
  }

  footer .box ul li {
    font-size: 12px;
  }

  footer .social-media a {
    font-size: 18px;
  }

  .legal {
    font-size: 10px;
  }
}
