.register-form {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.register-form h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  background: #3498db;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.btn.active {
  background: #2ecc71;
}

.btn:hover {
  background: #2980b9;
}

.form-content h3 {
  text-align: center;
  margin-bottom: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.form-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.document-list {
  list-style: none;
  padding: 0;
}

.document-list li {
  font-size: 14px;
  margin: 5px 0;
}

.btn-submit {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #2ecc71;
  color: white;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #27ae60;
}
